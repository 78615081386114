import PrivateRouter from "./Router";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak"

function App() {
  return (
    <div className="App">
      <ReactKeycloakProvider authClient={keycloak}>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <PrivateRouter />
      </ReactKeycloakProvider>
    </div>
  );
}

export default App;