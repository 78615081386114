import React from "react";
import "./cv.css";
import { useEffect } from "react";

function CV_EN() {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Lorenz Keefer CV";
  }, []);

  return (
    <div className="cvContainer">
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Amiko:wght@400;700&family=Bebas+Neue&display=swap"
        rel="stylesheet"
      />
      <div className="cvopenclose">&lt;&gt;</div>
      <div className="cvopencloseclose">&lt;/&gt;</div>
      <div className="cvWrapper">
        <div className="cvNameContainer">
          <div
            className="cvName"
            style={{ display: "flex", flexDirection: "row" }}
          >
            Lo
            <div style={{ display: "flex", flexDirection: "column" }}>
              renz
              <div>Keefer</div>
            </div>
          </div>

          <div className="cvProfession">Softwareengineering-Student</div>

          <div className="cvSection">
            / ABOUT ME
            <div className="cvDesc">
		I studied software engineering at the University of Stuttgart and enjoyed tinkering 
		with my own projects during my time of studies and work. One of them is this website, 
		which is hosted in my own cloud infrastructure.
            </div>
          </div>
        </div>
        <div className="cvSection">
          / Work Experience		
          <div className="cvSubSection">
						&gt;&gt; Team Lead 
						<div className="cvJobDesc">MESHR | February 2023 - Now </div>
						<div className="cvDesc">
							After winning a hackathon in Paris, the University of Oslo gave our team the opportunity to fully develop our Legal Tech project. 
							In my role as Team Lead, I had to make not only technical but also strategic decisions.
						</div>
					</div>
          <div className="cvSubSection">
            &gt;&gt; Bachelorand
            <div className="cvJobDesc">
              P3 Digital Services | April 2022 - October 
            </div>
            <div className="cvDesc">
              In addition to my bachelor's thesis, I also worked on a project.<br/>
		In that project I developed a React frontend and learned about new technologies such as RTK Query.
            </div>
          </div>
          <div className="cvSubSection">
            &gt;&gt; Webdeveloper React
            <div className="cvJobDesc">
              Flip GmbH | September 2019 - April 2022
            </div>
            <div className="cvDesc">
              When I started at Flip, the team consisted of 12 people. My tasks
              were varied and ranged from tool development to technology
              installation and maintenance. Over the course of time, I dealt
              with a wide variety of topics and was able to experience the
              growth of a start-up first-hand. Since October 2020, I have been
              maintaining the website and developing it further.
            </div>
          </div>
          <div className="cvSubSection">
            &gt;&gt; Systemadministrator Linux
            <div className="cvJobDesc">
              Universität Stuttgart Fakultät 8 • Mathematik und Physik | March
              2019 – September 2019
            </div>
            <div className="cvDesc">
              Maintenance and further development of the existing server
              structure as well as maintenance and introduction of new devices
              were standard tasks. Rarely, user assistance for professors and
              staff in need was also required.
            </div>
          </div>
          <div className="cvSubSection">
            &gt;&gt; Appdeveloper Android
            <div className="cvJobDesc">Incide UG | 2018 – 2019</div>
            <div className="cvDesc">
              In a team of five developers and a project manager, we worked on a
              new social network. This was primarily intended to connect people
              based on location by providing automated chats at so-called
              hotspots. I personally worked a lot on the functionality to take
              pictures, edit them efficiently and then share them. My biggest
              task was the development of the cluster algorithm, which should
              automatically recognise hotspots.
            </div>
          </div>
        </div>
        <div className="cvSection cvNewPage">
          / School Career
          <div className="cvSubSection">
						&gt;&gt; University of Oslo
						<div className="cvJobDesc">M.Sc Computer Science | August 2023 - now</div>
						<div className="cvDesc">
            For my Master's degree, I moved to Norway, Oslo, to learn from the best in their field at this absolutely unique computer science institute.
						</div>
					</div>
          <div className="cvSubSection">
            &gt;&gt; University of Stuttgart
            <div className="cvJobDesc">B.Sc Softwaretechnik | 2018 - October 2022	</div>
            <div className="cvDesc">
              In a research project in the field of artificial intelligence at
              the Fraunhofer Institute, I worked in a team of eight on an
              experimental method of semi-supervised learning, specifically
              active learning. In this project, I was not only able to learn a
              lot about artificial intelligence and research, but also to
              develop my DevOps skills.
            </div>
          </div>
          <div className="cvSubSection">
            &gt;&gt;Otto-Hahn Gymnasium Ostfildern
            <div className="cvJobDesc">Abitur | 2010 - 2018</div>
            <div className="cvDesc">
              Student representative
              <br />
              Head of the SMV sound engineering for school events
              <br />
              Foundation and management of the computer science club
            </div>
          </div>
        <div className="cvSection">
          / Technical Skills
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
						<div className="cvDesc">
							Python <br />
							TypeScript <br />
							Java <br />
							React.js <br />
							Docker <br />
							Node.js <br />
							Nginx <br />
							Git <br />
							Bash <br />
						</div>
						<div className="cvDesc">
							SQL <br />
							HTML5 <br />
							CSS <br />
							LaTeX <br />
							Grafana <br />
							Android <br />
							Traefik <br />
							Kotlin <br />
							Prometheus.io <br />
						</div>
          </div>
        </div>
	</div>
        <div className="cvSection">
          / Other Interests
          <div className="cvDesc">
            I work with my 3D printer, where I am interested in both the
            operational and modelling tasks. I do sports and am interested in
            literature.
          </div>
        </div>

        <div className="cvSection cvContact">
          / Contact
          <div className="cvDesc">
            &gt;&gt; $surname.$lastname@web.de
            <br />
            &gt;&gt; lorenz.keefer.de
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CV_EN;
