import "./Layout.css";
import Navbar from "../NavBar/NavBar";
function Layout({ children }) {
	return (
		<>
			{/* <Box sx={{ flexGrow: 1 }}>
				<AppBar position="static">
					<Toolbar>
						<IconButton
							size="large"
							edge="start"
							color="inherit"
							aria-label="menu"
							sx={{ mr: 2 }}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							News
						</Typography>
						<Button color="inherit">Login</Button>
					</Toolbar>
				</AppBar>
			</Box> */}
			<Navbar/>
			<div className="layout_container">{children}</div>
		</>
	);
}

export default Layout;
