import React from "react";
import "./cv.css";
import { useEffect } from "react";

function CV() {
	useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = "Lorenz Keefer CV";
	}, []);
	
	return (
		<div className="cvContainer">
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
			<link
				href="https://fonts.googleapis.com/css2?family=Amiko:wght@400;700&family=Bebas+Neue&display=swap"
				rel="stylesheet"
			/>
			<div className="cvopenclose">&lt;&gt;</div>
			<div className="cvopencloseclose">&lt;/&gt;</div>
			<div className="cvWrapper">
				<div className="cvNameContainer">
					<div
						className="cvName"
						style={{ display: "flex", flexDirection: "row" }}
					>
						Lo
						<div style={{ display: "flex", flexDirection: "column" }}>
							renz
							<div>Keefer</div>
						</div>
					</div>

					<div className="cvProfession">Softwaretechnik-Bachelor</div>

					<div className="cvSection">
						/ Über mich
						<div className="cvDesc">
							Ich habe Softwaretechnik an der Universit&auml;t Stuttgart studiert und w&auml;hrend
							meines Studiums und der Arbeit viel und gerne an eigenen Projekten gebastelt. Eines davon ist
							diese Website, welche in meiner eigenen Cloud Infrastruktur gehostet ist.
						</div>
					</div>
				</div>
				<div className="cvSection">
					/ Berufserfahrung
					<div className="cvSubSection">
						&gt;&gt; Team Lead 
						<div className="cvJobDesc">MESHR | February 2023 - Now </div>
						<div className="cvDesc">
							Nach einem gewonnenen Hackathon in Paris bot die Universität Oslo unserem Team die Möglichkeit unser Legal Tech Projekt vollständig zu entwickeln. 
							In meiner Rolle als Team Lead hatte ich nicht nur die technischen sondern auch stragegische Entscheidungen zu treffen.
						</div>
					</div>
					<div className="cvSubSection">
						&gt;&gt; Bachelorand
						<div className="cvJobDesc">P3 Digital Services | April 2022 - Oktober 2022</div>
						<div className="cvDesc">
							Neben meiner Bachelorarbeit habe ich zusätzlich in einem Projekt mitgewirkt. <br/>
							In diesem habe ich im Frontend mit React gearbeitet und neue Techniken wie RTK Query kennengelernt.
						</div>
					</div>
					<div className="cvSubSection">
						&gt;&gt; Webentwickler React
						<div className="cvJobDesc">Flip GmbH | September 2019 - April 2022</div>
						<div className="cvDesc">
							Als ich bei Flip angefangen habe bestand das Team noch aus 12
							Mitarbeitern. Meine Aufgaben waren vielfältig und reichten von der
							Toolentwicklung bis zur Technikinstallation und Wartung.
							<br />
							Ich habe mich im Laufe der Zeit mit verschiedensten Themen
							auseinandergesetzt und konnte das Wachstum eines Startups hautnah
							miterleben.
							<br />
							Seit Oktober 2020 warte ich die Website und entwickle sie weiter.
						</div>
					</div>
					<div className="cvSubSection">
						&gt;&gt; Systemadministrator Linux
						<div className="cvJobDesc">
							Universität Stuttgart Fakultät 8 • Mathematik und Physik | März
							2019 – September 2019
						</div>
						<div className="cvDesc">
							Wartung und Weiterentwicklung der bestehenden Serverstruktur sowie
							Pflege und Einführung neuer Geräte gehörten zu den
							Standardaufgaben. Selten war auch Benutzerhilfe für bedürftige
							Professoren und Mitarbeiter gefordert.
						</div>
					</div>
					<div className="cvSubSection">
						&gt;&gt; Appentwickler Android
						<div className="cvJobDesc">Incide UG | 2018 – 2019</div>
						<div className="cvDesc">
							In einem Team von fünf Entwicklern und einem Projektmanager haben
							wir an einem neuen sozialen Netzwerk gearbeitet. Dieses sollte
							primär standortbasiert Menschen verbinden, indem an sogenannten
							Hotspots automatisiert Chats zur Verfügung gestellt wurden. Ich
							persönlich habe viel an der Funktionalität Bilder aufzunehmen,
							effizient zu bearbeiten und diese dann teilen zu können
							gearbeitet. Meine größte Aufgabe war die Entwicklung des Cluster
							Algorithmus, welcher automatisiert Hotspots erkennen sollte.
						</div>
					</div>
				</div>

				<div className="cvSection cvNewPage">
					/ Schulische Laufbahn
					<div className="cvSubSection">
						&gt;&gt; University of Oslo
						<div className="cvJobDesc">M.Sc Computer Science | August 2023 - heute</div>
						<div className="cvDesc">
							Für meinen Master bin ich nach Norwegen, Oslo gezogen um an diesem absolut einzigartigen Informatik Institut
							von den Besten ihres Faches zu lernen. 
						</div>
					</div>
					<div className="cvSubSection">
						&gt;&gt; Universität Stuttgart
						<div className="cvJobDesc">B.Sc Softwaretechnik | 2018 - Oktober 2022</div>
						<div className="cvDesc">
							In einem Forschungsprojekt im Bereich künstlicher Intelligenz am
							Fraunhofer Institut habe ich in einem achtköpfigen Team an einer
							experimentellen Methode des semi-supervised learnings, speziell
							active learning, geforscht. Ich konnte in diesem Projekt nicht nur
							viel über künstliche Intelligenz und Forschung lernen, sondern
							auch meine DevOps Skills weiterentwickeln.
						</div>
					</div>
					<div className="cvSubSection">
						&gt;&gt;Otto-Hahn Gymnasium Ostfildern
						<div className="cvJobDesc">Abitur | 2010 - 2018</div>
						<div className="cvDesc">
							Schülersprecher <br />
							Leitung der SMV Tontechnik für schulische Events <br />
							Gründung und Leitung der Informatik AG
						</div>
					</div>
				<div className="cvSection">
					/ Technische Kompetenzen
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
						}}
					>
						<div className="cvDesc">
							Python <br />
							TypeScript <br />
							Java <br />
							React.js <br />
							Docker <br />
							Node.js <br />
							Nginx <br />
							Git <br />
							Bash <br />
						</div>
						<div className="cvDesc">
							SQL <br />
							HTML5 <br />
							CSS <br />
							LaTeX <br />
							Grafana <br />
							Android <br />
							Traefik <br />
							Kotlin <br />
							Prometheus.io <br />
						</div>
					</div>
				</div>
</div>
				<div className="cvSection">
					/ Sonstige Interessen
					<div className="cvDesc">
						Ich arbeite mit meinem 3D Drucker, an welchem mich sowohl der
						operative Betrieb, wie auch die Modeling Aufgaben interessieren. Ich
						treibe Sport und bin an Literatur interessiert.
					</div>
				</div>

				<div className="cvSection cvContact">
					/ Kontaktdaten
					<div className="cvDesc">
						Allmandring 26D, 70569 Stuttgart
						<br />
						<br />
						&gt;&gt; $vorname.$nachname@web.de
						<br />
						&gt;&gt; lorenz.keefer.de
						<br />
						<br />
					</div>
				</div>
			</div>
		</div>
	);
}

export default CV;
