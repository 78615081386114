import "./NavBar.css";
import SideBar from "../SideBar/SideBar";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import CloudIcon from "@mui/icons-material/Cloud";
import AlarmIcon from "@mui/icons-material/Alarm";
import MenuIcon from "@mui/icons-material/Menu";

function NavBar() {
	const [showSidebar, setshowSidebar] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();

	const activeSelection = [
		{ name: "", value: false },
		{ name: "weather", value: false },
		{ name: "alarm", value: false },
		{ name: "about", value: false },
	];

	activeSelection.forEach((item) => {
		location.pathname.split("/")[1] === item.name
			? (item.value = true)
			: (item.value = false);
	});

	function openHome() {
		navigate("/");
	}

	function openWeather() {
		navigate("/weather");
	}

	function handleSidebar() {
		setshowSidebar(!showSidebar);
	}

	return (
		<>
			<SideBar value={showSidebar} callback={setshowSidebar} />
			<div className="container">
				<div className="NavBarWrapper">
					<div
						className={`MenuItem ${activeSelection[0].value ? "active" : ""}`}
						onClick={openHome}
					>
						<HomeIcon />
					</div>
					<div
						className={`MenuItem ${activeSelection[1].value ? "active" : ""}`}
					onClick={openWeather}>
						<CloudIcon />
					</div>
					<div
						className={`MenuItem ${activeSelection[2].value ? "active" : ""}`}
					>
						<AlarmIcon />
					</div>
					<div
						className={`MenuItem ${activeSelection[3].value ? "active" : ""}`}
						onClick={() => handleSidebar()}
					>
						<MenuIcon />
					</div>
				</div>
			</div>
		</>
	);
}

export default NavBar;
