import "./Index.css";
import Link from "./Link";
import DeciderIcon from "./res/decider.png"
import RouletteIcon from "./res/roulette.png"
import BeerpongIcon from "./res/beerpong.png"
import OnlyIcon from "./res/only.png"
 
function Index() {
  return (
    <div className="index_parent_container">
      <p>Herzlich willkommen auf der Keefer Website</p>
      <div className="grid">
        <Link title="Roulette" icon={RouletteIcon} link="/roulette"/>
        <Link title="Decider" icon={DeciderIcon} link="/decider"/>
        <Link title="Beerpong" icon={BeerpongIcon} link="/beerpong"/>
        <Link title="OnlyFans" icon={OnlyIcon} link="rick"/>
      </div>
    </div>
  );
}

export default Index;
