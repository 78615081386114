import "./Weather.css";
import ReactWeather, { useOpenWeather } from "react-open-weather";
import Layout from "../../components/Layout/Layout";

function Weather() {
	const dataStuttgart = useOpenWeather({
		key: "fd9e7de10db46dc4ba79f3568dcaf468",
		lat: "48.78232",
		lon: "9.17702",
		lang: "en",
		unit: "metric",
	});

	const dataKonstanz = useOpenWeather({
		key: "fd9e7de10db46dc4ba79f3568dcaf468",
		lat: "47.665961",
		lon: "9.176627",
		lang: "en",
		unit: "metric",
	});

	const dataOstfildern = useOpenWeather({
		key: "fd9e7de10db46dc4ba79f3568dcaf468",
		lat: "48.71671850797466",
		lon: "9.272700112209105",
		lang: "en",
		unit: "metric",
	});

	const dataFreiburg = useOpenWeather({
		key: "fd9e7de10db46dc4ba79f3568dcaf468",
		lat: "48.00038383139449",
		lon: "7.786695857545717",
		lang: "en",
		unit: "metric",
	});

	const dataBowlingGreen = useOpenWeather({
		key: "fd9e7de10db46dc4ba79f3568dcaf468",
		lat: "36.984411",
		lon: "-86.460779",
		lang: "en",
		unit: "metric",
	});

	return (
		<Layout>
			<div className="WeatherContainer">
				<div className="WeatherGrid">
					<div>
						<ReactWeather
							isLoading={dataStuttgart.isLoading}
							errorMessage={dataStuttgart.errorMessage}
							data={dataStuttgart.data}
							lang="en"
							locationLabel="Stuttgart"
							unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
							showForecast
						/>
					</div>
					<div>
						<ReactWeather
							isLoading={dataBowlingGreen.isLoading}
							errorMessage={dataBowlingGreen.errorMessage}
							data={dataBowlingGreen.data}
							lang="en"
							locationLabel="Bowling Green"
							unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
							showForecast
						/>
					</div>
					<div>
						<ReactWeather
							isLoading={dataKonstanz.isLoading}
							errorMessage={dataKonstanz.errorMessage}
							data={dataKonstanz.data}
							lang="en"
							locationLabel="Konstanz"
							unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
							showForecast
						/>
					</div>

					<div>
						<ReactWeather
							isLoading={dataOstfildern.isLoading}
							errorMessage={dataOstfildern.errorMessage}
							data={dataOstfildern.data}
							lang="en"
							locationLabel="Ostfildern"
							unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
							showForecast
						/>
					</div>

					<div>
						<ReactWeather
							isLoading={dataFreiburg.isLoading}
							errorMessage={dataFreiburg.errorMessage}
							data={dataFreiburg.data}
							lang="en"
							locationLabel="Freiburg"
							unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
							showForecast
						/>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Weather;
