import Layout from "../../components/Layout/Layout";
import "./bookmarks.css";
function Bookmarks() {
	return (
		<Layout>
			<div className="bookmarks_grid">
				<div className="bookmarks">
					<a target="_blank" href="https://calendar.google.com/calendar/" rel="noreferrer">
						<img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Calendar_icon_%282020%29.svg/1200px-Google_Calendar_icon_%282020%29.svg.png" alt="bookmark"/>
					</a>
				</div>
                <div className="bookmarks">
					<a target="_blank" href="https://liquipedia.net/dota2/Main_Page" rel="noreferrer">
						<img src="https://liquipedia.net/commons/extensions/TeamLiquidIntegration/resources/pagelogo/liquipedia_icon_menu.png" alt="bookmark"/>
					</a>
				</div>
			</div>
		</Layout>
	);
}

export default Bookmarks;
