import "./SideBar.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SideBar = (props) => {
  const navigate = useNavigate();

  const [allowAnimation, setallowAnimation] = useState(false);
  if (!allowAnimation) if (props.value) setallowAnimation(true);

  let animateIn = false;
  let animateOut = false;

  if (props.value && allowAnimation) animateIn = true;
  if (!props.value && allowAnimation) animateOut = true;
  function handleSidebar() {
    props.callback(!props.value);
  }

  function handleClick(e) {
    navigate("/" + e.target.id);
  }
  return (
    <>
      <div
        className={`sidebar_container ${animateIn ? "fade_in_container" : ""} ${
          animateOut ? "fade_out_container" : ""
        }`}
      >
        <div className="sidebar_entry" id="roulette" onClick={handleClick}>
          Roulette
        </div>
        <div className="sidebar_entry" id="decider" onClick={handleClick}>
          Decider
        </div>
        <div className="sidebar_entry" id="bookmarks" onClick={handleClick}>
          Bookmarks
        </div>
        <div className="sidebar_entry" id="calendar">
          Calendar
        </div>
        <div className="sidebar_entry">test</div>
        <div className="sidebar_entry">test</div>
      </div>
      <div
        className={`sidebar_overlay ${animateIn ? "fade_in_overlay" : ""} ${
          animateOut ? "fade_out_overlay" : ""
        }`}
        onClick={() => handleSidebar()}
      ></div>
    </>
  );
};

export default SideBar;
