import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Index from "./pages/Index/Index";
import About from "./pages/About/About";
import Weather from "./pages/Weather/Weather";
import ThankYou from "./pages/ThankYou/ThankYou";
import Roulette from "./pages/Roulette/roulette";
import Decider from "./pages/Decider/decider";
import Bookmarks from "./pages/Bookmarks/bookmarks";
import Seminar from "./pages/Seminar/seminar";
import Tuuun from "./pages/Tuuun/Tuuun";
import CV from "./pages/CV/cv";
import CV_EN from "./pages/CV/cv_en";
import Protected from "./components/Protected";
import Ba from "./pages/Ba/ba";
import Beerpong from "./pages/Beerpong/beerpong"

function PrivateRouter() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
                <Index />
            }
          />
          <Route
            exact
            path="/about"
            element={
              <Protected>
                <About />
              </Protected>
            }
          />
          <Route
            exact
            path="/weather"
            element={
              <Protected>
                <Weather />
              </Protected>
            }
          />
          <Route exact path="/roulette" element={<Roulette />} />
          <Route exact path="/seminar" element={<Seminar />} />
          <Route exact path="/DankeHanna" element={<ThankYou />} />
          <Route exact path="/decider" element={<Decider />} />
          <Route exact path="/tuuun" element={<Tuuun />} />
          <Route
            exact
            path="/bookmarks"
            element={
              <Protected>
                <Bookmarks />
              </Protected>
            }
          />
          <Route exact path="/ba" element={<Ba />} />
          <Route exact path="/beerpong" element={<Beerpong />} />
          <Route exact path="/cv" element={<CV />} />
          <Route exact path="/en/cv" element={<CV_EN />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default PrivateRouter;
