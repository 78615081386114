import "./ThankYou.css";

function ThankYou() {
  return (
    <div className="thanks-container">
      <div className="thanks-wrapper">
        <img
          className="thanks-image"
          src="https://media4.giphy.com/media/SY2hQpAMLnuxtgLT5C/giphy.gif?cid=ecf05e47wmhmdefv8k4p7q47b4f782pqhel8qpjuznoxrxr9&rid=giphy.gif"
          width="100%"
          alt="Danke Hanna Gif"
        />
        <h1 className="thanks-heading">Hallo Hanna,</h1>
        vielen lieben Dank, dass ich bereits zum zweiten Mal in deinem Bett
        schlafen durfte. Als kleines Dankeschön habe ich mich ein bisschen um
        dein Fahrrad gekümmert. Vielleicht lernen wir uns eines Tages persönlich
        kennen :)
        <p className="thanks-heading">Liebe Grüße</p>
        <p className="thanks-heading">Lorenz</p>
      </div>
    </div>
  );
}

export default ThankYou;
