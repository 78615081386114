import React from "react";
import { Wheel } from "react-custom-roulette";
import { useState } from "react";
import "./roulette.css";

function Roulette() {
	const data = [
		{ option: "0", style: { backgroundColor: "green", textColor: "black" } },
	];

	for (let i = 1; i < 36; i++) {
		data.push({ option: i });
	}

	const [wager, setWager] = useState(100);
	const [balance, setBalance] = useState(0);
	const [prizeNumber, setPrizeNumber] = useState(100);
	const [spins, setSpins] = useState(0);
	const [mustStartSpinning, setMustStartSpinning] = useState(false);

	function startBtnClick() {
		setPrizeNumber(Math.floor(Math.random() * 36));
		setMustStartSpinning(true);
	}

	function onStopSpinning() {
		if (didWin()) {
			setBalance((balance) => wager * 2 + balance);
		} else {
			setBalance((balance) => balance - wager);
		}
		setMustStartSpinning(false);
		setSpins((spins) => spins + 1);
		setWager(wager * 2);
	}

	function didWin() {
		//always bet on red
		return prizeNumber % 2 === 1;
	}

	return (
		<div className="rouletteContainer">
			<h1>Roulette</h1>
			<Wheel
				mustStartSpinning={mustStartSpinning}
				prizeNumber={prizeNumber}
				data={data}
				backgroundColors={["#3e3e3e", "#df3428"]}
				textColors={["#ffffff"]}
				innerRadius={50}
				textDistance={75}
				onStopSpinning={() => onStopSpinning()}
			/>

			<label>Wager: {wager}</label>
			<p />
			<label>Balance: {balance}</label>
			<p />
			<label>Spins: {spins}</label>
			<p />
			<label>Bet on: red</label>
			<p />
			<button onClick={() => startBtnClick()}>Start Spinning</button>
		</div>
	);
}

export default Roulette;
