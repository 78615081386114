import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Grid from "@mui/material/Grid";
import './ba.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Ba = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const pagearray = [];
  for (let i = 1; i < numPages; i++) {
    pagearray.push(
      <Grid item xs={12} lg={6}>
        <Page scale={1} width={766} pageNumber={i} className={'react_pdf_page_style'} />
      </Grid>
    );
  }
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Document
          file="/Bachelor_Thesis.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
          className={'react_pdf_document_style'}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {pagearray}
          </Grid>
        </Document>
      </div>
    </>
  );
};
export default Ba;
