import "./Link.css";
import { useNavigate } from "react-router-dom";

function Link({ title, icon, link }) {
  console.log(icon);
  const navigate = useNavigate();
  return (
    <>
      <div
        className="link-grid-item"
        onClick={() => {
          if (link === "rick") {
            for (let i = 0; i < 100; i++) {
              window
                .open("https://www.youtube.com/watch?v=dQw4w9WgXcQ", "_blank")
                .focus();
            }
          } else {
            navigate(link);
          }
        }}
      >
        <div className="link">
          <img src={icon} className="link_icon" alt="icon" />
        </div>
        <div>{title}</div>
      </div>
    </>
  );
}

export default Link;
