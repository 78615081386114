import React from "react";
import { Wheel } from "react-custom-roulette";
import { useState } from "react";
import './decider.css'

function Decider() {
    const [optionsString, setoptionsString] = useState("option1;option2")
	const [mustStartSpinning, setMustStartSpinning] = useState(false);
	const [prizeNumber, setPrizeNumber] = useState(0);
	const [data, setData] = useState([{ option: "option1"},{ option: "option2"}]);

	function handleInput(e) {
		let optionsArray = e.target.value.split(";");
		let newDataArray = [];
		for (let myOption in optionsArray) {
			newDataArray.push({ option: optionsArray[myOption] });
		}
        setoptionsString(e.target.value)
		setData(newDataArray);
	}

	function startBtnClick() {
		setPrizeNumber(Math.floor(Math.random() * data.length));
		setMustStartSpinning(true);
	}

	return (
		<div className="deciderContainer">
            <h1>Entscheider</h1>
			<Wheel
				mustStartSpinning={mustStartSpinning}
				prizeNumber={prizeNumber}
				data={data}
				backgroundColors={["#ff8f43", "#70bbe0", "#0b3351", "#f9dd50"]}
				textColors={["#ffffff"]}
				innerRadius={50}
				textDistance={75}
				onStopSpinning={() => setMustStartSpinning(false)}
			/>
			<button onClick={startBtnClick}>Start Spinning</button>
			<input className="deciderInput" onChange={(e) => handleInput(e)} value={optionsString}/>
		</div>
	);
}

export default Decider;
