import "./beerpong.css";
import React from "react";
import Ellenbogen from "./res/ellenbogen.png";
import Doppeltrefferu from "./res/doppeltreffer_u.png";
import Doppeltrefferg from "./res/doppeltreffer_g.png";
import Rollback from "./res/rollback.png";
import Blow from "./res/blow.png";
import Last from "./res/last.png";
import Bounce from "./res/bounce.png";
import Reorder from "./res/reorder.png";
const Beerpong = () => {
  return (
    <>
      <div className="beerpong_container pagebreak">
        <div className="beerpong_column">
          <h1>Beerpong Regeln</h1>
          Nach diesen Regeln wird gespielt. Regeln welche hier nicht stehen
          m&uuml;ssen von beiden Parteien zugestimmt werden. Keine Widerrede!
          <h2>Ellenbogen</h2>
          <div className="beerpong_rule">
            <div>
              Der Ellenbogen muss w&auml;hrend der gesamten Wurfbewegung hinter
              der Tischkante bleiben.
            </div>
            <img src={Ellenbogen} className="beerpong_graphic" />
          </div>
          <h2>Wurfrichtung</h2>
          <div className="beerpong_rule">
            <div>
              Es wird immer von oben geworfen. F&uuml;r die meisten ist das
              normal.
            </div>
          </div>
          <h2>Doppeltreffer - unterschiedliche Becher</h2>
          <div className="beerpong_rule">
            <div>
              Wenn beide Spieler treffen, bekommen beide ihren Ball zur&uuml;ck
              und d&uuml;rfen nochmal werfen. Es darf aber nicht umgestellt
              werden.
            </div>
            <img src={Doppeltrefferu} className="beerpong_graphic_small" />
          </div>
          <h2>Doppeltreffer - gleicher Becher</h2>
          <div className="beerpong_rule">
            <div>
              Wenn beide Spieler den gleichen Becher treffen, z&auml;hlt das als
              3 Becher. Das Team welches getroffen hat darf sich zwei weitere
              Becher aussuchen welche weggestellt werden m&uuml;ssen.
              Zus&auml;tzlich tritt die Regel "
              <b>Doppeltreffer - unterschiedliche Becher</b>" in Kraft.
            </div>
            <img src={Doppeltrefferg} className="beerpong_graphic_small" />
          </div>
          <h2>Ball rollt zur&uuml;ck</h2>
          <div className="beerpong_rule">
            <div>
              Wenn der Ball &uuml;ber die Mitte des Tisches zur&uuml;ck rollt
              und das Team welches geworfen hat diesen f&auml;ngt, ohne, dass
              dieser auf dem Boden war, dann darf ein Trickshot geworfen werden.
              <br />
              Falls der Ball auf den Boden kommt oder das andere Team ihn
              bekommt gibt es keinen Trickshot.
            </div>
            <img src={Rollback} className="beerpong_graphic" />
          </div>
          <h2>Rauspusten</h2>
          <div className="pagebreak beerpong_rule">
            <div>
              Wenn der Ball sich in einem Becher dreht darf er rausgepustet
              werden. Wenn der Ball nass ist nach dem Rauspusten z&auml;hlt er
              als Treffer.
            </div>
            <img src={Blow} className="beerpong_graphic" />
          </div>
        </div>
      </div>
      <div className="beerpong_container" style={{marginBottom: "50px"}}>
        <div className="beerpong_column">
          <h2 className="">Aufsetzer</h2>
          <div className="beerpong_rule pagebreak">
            <div>
              Wenn ein Becher &uuml;ber einen Aufsetzer getroffen wird, gilt
              dieser doppelt. Er darf jedoch auch weggeschlagen werden nachdem
              er auf dem Tisch aufgekommen ist. Wenn man dar&uuml;ber
              diskutieren kann ob der Ball zuerst einen Becher und dann den
              Tisch ber&uuml;hrt hat oder andersherum gilt im Zweifelsfall kein
              Aufsetzer.
            </div>
            <img src={Bounce} className="beerpong_graphic" />
          </div>
          <h2>Doppeltreffer mit Aufsetzer</h2>
          <div className="beerpong_rule">
            <div>
              Je nach Fall wird die passende Doppeltreffer Regel angewendet und
              ein zus&auml;tzlicher Becher weggestellt.
            </div>
          </div>
          <h2>Umstellen</h2>
          <div className="beerpong_rule">
            <div>
              Ab der H&auml;lfte der Becher darf einmal umgestellt werden. Immer
              ganz nach vorne im Dreieck stellen.
            </div>
            <img src={Reorder} className="beerpong_graphic_small" />
          </div>
          <h2>Letzter Becher</h2>
          <div className="beerpong_rule">
            <div>
              Der letzte Becher muss getroffen werden. Man kann also das Spiel
              nicht mit einem "<b>Aufsetzer</b>" oder "
              <b>Doppeltreffer - gleicher Becher</b>" beenden.
            </div>
            <img src={Last} className="beerpong_graphic" />
          </div>
          <h2>R&uuml;ckwurf</h2>
          <div className="beerpong_rule">
            <div>
              Es gibt immer einen R&uuml;ckwurf, wenn das erste Mal der letzte
              Becher getroffen wird, bei einem Team. Falls der R&uuml;ckwurf
              getroffen wird, wird der letzte Treffer der Gegner annuliert.
              Falls man im R&uuml;ckwurf doppelt trifft, wird mit einem Treffer
              der letzte Treffer der Gegner annuliert und f&uuml;r den anderen
              Treffer wird ein Becher weggestellt.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Beerpong;
