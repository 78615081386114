import { useKeycloak } from "@react-keycloak/web";

const ProtectedRoute = (props) => {
  const { keycloak } = useKeycloak();
  console.log(keycloak.authServerUrl)
  return (
    <>
      {keycloak.authenticated ? (
        props.children
      ) : (
        <div>
          <p>You are not logged in</p>
          <button onClick={keycloak.login}>Login</button>
        </div>
      )}
    </>
  );
};
export default ProtectedRoute;
