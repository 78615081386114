import "./Tuuun.css";
import TuuunDino from './IMG-20200125-WA0015.jpg';
import TuuunComputer from './IMG-20191103-WA0008.jpg'
import TuuunSonnenbrille from './IMG-20200626-WA0002.jpg'
import UnzufrieTuuun from './IMG-20170831-WA0004.jpg'
import PartyTuuun from './IMG-20201103-WA0020.jpg'
function Tuuun() {
  return (
    <div className="tuuun-container">
      <div className="tuuun-wrapper">
        <img
          className="tuuun-image"
          src={TuuunDino}
          width="100%"
          alt="Tuuun Dino"
        />
        <h1 className="tuuun-heading">Hey Tuuun,</h1>
        ich wünsch dir ganz viel Spass in Bowling Green. Wir sehen uns jetzt wahrscheinlich
        erstmal noch weniger als davor aber das gehört wohl dazu. Lass mal was von dir
        hören und komm gesund wieder.
        <p className="tuuun-heading">Liebe Grüße</p>
        <p className="tuuun-heading">Lorenz</p>
        <img
          className="tuuun-image"
          src={TuuunComputer}
          width="100%"
          alt="Tuuun Dino"
        />
        <img
          className="tuuun-image"
          src={TuuunSonnenbrille}
          width="100%"
          alt="Tuuun Dino"
        />
        <img
          className="tuuun-image"
          src={UnzufrieTuuun}
          width="100%"
          onClick={() => window.open("https://www.amazon.com/Images-You-Should-Not-Masturbate/dp/0399536493?tag=judgeabook-20")}
          alt="Tuuun Dino"
        />
        <img
          className="tuuun-image"
          src={PartyTuuun}
          width="100%"
          onClick={() => window.open("https://www.youtube.com/watch?v=YCtKey3Sto4")}
          
          alt="Tuuun Dino"
        />
      </div>
    </div>
  );
}

export default Tuuun;
